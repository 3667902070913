@import '../../../styles/globalVariables';

.backHeader {
    display: none;
    @include for-size(phone-only) {
        height: 52px;
        display: flex;
        grid-gap: 12px;
        align-items: center;
        padding: 0 12px;
        border-bottom: 1px solid #E1E4E8;
        html[dir='rtl'] & {
            padding: 0 12px  0 0px;
          }
        .BackBtn {
            display: flex;
            width: 28px;
            height: 28px;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 24px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            background: rgba(255, 255, 255, 0.70);
            cursor: pointer;
            i {
                width: 9.8px;
                height: 8px;
                flex-shrink: 0;
                color: #566470;

                [dir="rtl"] & {
                    transform: rotate(180deg);
                }
            }
        }
        
        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #191919;
    }
 }
 &.closeBtnForSuccess{
    justify-content: flex-end;
}
}
.desktopBackHeader {
        height: 52px;
        display: flex;
        grid-gap: 16px;
        align-items: center;
        padding: 0 16px;
        html[dir='rtl'] & {
            padding: 0 12px  0 0px;
          }
        .BackBtn {
            background: transparent;
            border: 0;
            padding: 0;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i {
                display: flex;
                color: #191919;

                [dir="rtl"] & {
                    transform: rotate(180deg);
                }
            }
        }

        .backText {
            color: #191919;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #191919;
    }
}
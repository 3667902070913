@import '../../../styles/globalVariables';

.modelOverlay {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999 !important;
  overflow-y: auto;

  &.innerPopup {
    background-color: rgba(0, 0, 0, 0.4);

    .modalBox {
      margin-top: 78px;

      @include for-size(phone-only) {
        margin-top: auto;
      }
    }

        .modalBody {
          max-width: 576px;
    
          @include for-size(phone-only) {
            width: 100%;
          }
        }
  }

  .modalBox {
    overflow-y: auto;
    margin: 64px 0 64px 0;
    background: #fff;
    border-radius: 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    position: relative;

    &.ewalletPopup {
      .modalBody {
        padding: 0;
      }

      .HeaderTitle {
        padding: 0;

        i {
          color: #fff;
        }

        @include for-size(phone-only) {
          display: none;
        }
      }
    }

    .sadad_popup_close i {
      top: 16px !important;
    }

    @include for-size(phone-only) {
      max-height: calc(100% - 50px);

      &.locationBody {
        max-height: calc(100% - 24px);
        height: 100%;
      }

      &.largeModalBox {
        max-height: 97vh;
        height: 97vh;
      }

      &.mCloseAuction {
        max-height: 100%;
        height: 100%;
        border-radius: 0;

        .modalBody {
          height: 98%;
          display: flex;
          align-items: center;
          padding-bottom: 0;
        }


      }

      &.fullPopup {
        height: 100%;
        max-height: calc(100%);
        border-radius: 0;
      }

    }

    @include for-size(tablet-phone) {
      max-width: 732px;
    }

    @include for-size(phone-only) {
      width: 100%;
      margin-top: auto;
      border-radius: 20px 20px 0 0;
      margin-bottom: 0;
      // max-height: 90%;
      overflow-y: hidden;
    }

    .HeaderTitle {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: $pad24 $pad32 $pad0 $pad32;

      html[dir='rtl'] & {
        padding: $pad24 $pad32 $pad0 $pad32;
      }

      @include for-size(tablet-up) {
        &.fHead {
          background: #fff;
          border-radius: 12px;
          position: sticky;
          top: 0;
        }
      }

      @include for-size(phone-only) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        padding: $pad12 $pad12 $pad12 $pad16;

        html[dir='rtl'] & {
          padding: $pad12 $pad16 $pad12 $pad12;
        }
      }

      h2 {
        width: calc(100% - 40px);
        color: $lightBlack;
        font-size: $font18;
        font-weight: $font-semi-bolder;
        line-height: 24px;
      }
    }

    .rentalHeaderTitle {
      padding: 24px 24px 24px 24px !important;
      border-bottom: 1px solid #F7F7F7 !important;
      box-shadow: 0px 0.408px 0.77px 0px rgba(0, 0, 0, 0.07), 0px 1.127px 2.129px 0px rgba(0, 0, 0, 0.05), 0px 2.713px 5.125px 0px rgba(0, 0, 0, 0.04) !important;

      i {
        position: absolute;
        right: 24px;
        top: 22px;

        html[dir='rtl'] & {
          right: auto;
          left: 24px;
        }

        @include for-size(phone-only) {
          order: 2;
          position: inherit;

          html[dir='rtl'] & {
            order: 1;
            left: 8px;
            right: auto;
          }
        }
      }

      @include for-size(phone-only) {
        padding: 16px !important;
      }
    }

    .modalBody {
      padding: 0px 32px 32px 32px;

      pre {
        white-space: pre-wrap;
      }

      &.noSpace {
        padding: 32px;
        padding-top: 0px;
      }

      @include for-size(phone-only) {
        padding: $pad16 $pad16;
        padding-top: $pad0;
        padding-bottom: 8px;
        max-height: calc(90vh - 114px);
        overflow-y: scroll;
        margin-bottom: $mrg0;

        &.noSpace {
          max-height: calc(100% - 59px);
          height: 100%;
          padding: 0px;
          margin-bottom: 0px
        }
      }
    }

    .modalBodySadadInvoiceScreen {
      @include for-size(phone-only) {
        padding: 0 $pad16 0 !important;
      }
    }

    .tCModalbody {
      margin-bottom: 192px !important;
      padding: 0px 32px 0px 32px;

      @include for-size(phone-only) {
        padding: 0px 0px 0px 0px;
      }

    }

    &.spaceOutside {
      @include for-size(phone-only) {
        width: calc(100% - 16px);
        border-radius: 20px;
        margin-bottom: 8px;
      }
    }

    .rentalModalBody {
      padding: 20px 24px 0px 24px;
      background-color: #F7F7F7;
      width: 907px;

      @include for-size(phone-only) {
        width: 100%;
        padding: 20px 20px 0px 20px;
      }
    }

  }

  .rentalModalBox {
    overflow-y: auto;
    margin: 64px 0 64px 0;
    background: #fff;
    border-radius: 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    position: relative;

    &.ewalletPopup {
      .modalBody {
        padding: 0;
      }

      .HeaderTitle {
        padding: 0;

        i {
          color: #fff;
        }

        @include for-size(phone-only) {
          display: none;
        }
      }
    }

    .sadad_popup_close i {
      top: 16px !important;
    }

    @include for-size(phone-only) {
      max-height: calc(100% - 50px);

      &.locationBody {
        max-height: calc(100% - 24px);
        height: 100%;
      }

      &.largeModalBox {
        max-height: 97vh;
        height: 97vh;
      }

      &.mCloseAuction {
        max-height: 100%;
        height: 100%;
        border-radius: 0;

        .modalBody {
          height: 98%;
          display: flex;
          align-items: center;
          padding-bottom: 0;
        }
      }

      &.fullPopup {
        height: 100%;
        max-height: calc(100%);
        border-radius: 0;

        @include for-size(phone-only) {
          height: auto;
        }
      }

    }

    @include for-size(tablet-phone) {
      max-width: 732px;
    }

    @include for-size(phone-only) {
      width: 100%;
      margin-top: auto;
      border-radius: 20px 20px 0 0;
      margin-bottom: 0;
      // max-height: 90%;
      overflow-y: auto;
    }

    .HeaderTitle {
      padding: 28px 32px 0px 32px;

      @include for-size(tablet-up) {
        &.fHead {
          background: #fff;
          border-radius: 12px;
          position: sticky;
          top: 0;
        }
      }

      @include for-size(phone-only) {
        padding: 16px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
      }

      i {
        position: absolute;
        right: 8px;
        top: 8px;
        display: flex;
        padding: 6px;
        cursor: pointer;

        html[dir='rtl'] & {
          right: auto;
          left: 8px;
        }

        @include for-size(phone-only) {
          order: 2;
          position: inherit;

          html[dir='rtl'] & {
            order: 1;
            left: 8px;
            right: auto;
          }
        }
      }

      h2 {
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;
        color: #222222;

        @include for-size(phone-only) {
          font-size: 20px;
          line-height: 24px;
          color: #262626;
        }
      }
    }

    .rentalHeaderTitle {
      padding: 24px 24px 24px 24px !important;
      border-bottom: 1px solid #F7F7F7 !important;
      box-shadow: 0px 0.408px 0.77px 0px rgba(0, 0, 0, 0.07), 0px 1.127px 2.129px 0px rgba(0, 0, 0, 0.05), 0px 2.713px 5.125px 0px rgba(0, 0, 0, 0.04) !important;

      i {
        position: absolute;
        right: 24px;
        top: 22px;

        html[dir='rtl'] & {
          right: auto;
          left: 24px;
        }

        @include for-size(phone-only) {
          order: 2;
          position: inherit;

          html[dir='rtl'] & {
            order: 1;
            left: 8px;
            right: auto;
          }
        }
      }

      @include for-size(phone-only) {
        padding: 16px !important;
        position: fixed;
        width: 100%;
        background-color: #fff;
        z-index: 10;
      }
    }

    .modalBody {
      padding: 0px 32px 32px 32px;

      pre {
        white-space: pre-wrap;
      }

      &.noSpace {
        padding: 32px;
        padding-top: 0px;
      }

      @include for-size(phone-only) {
        padding: 0px 16px 0px 16px;
        max-height: calc(90vh - 114px);
        overflow-y: scroll;
        margin-bottom: 0px;
        margin-top: 58px;

        &.noSpace {
          max-height: calc(100% - 59px);
          height: 100%;
          padding: 0px;
          margin-bottom: 0px
        }
      }
    }

    .tCModalbody {
      margin-bottom: 192px !important;
      padding: 0px 32px 0px 32px;

      @include for-size(phone-only) {
        padding: 0px 0px 0px 0px;
      }

    }

    &.spaceOutside {
      @include for-size(phone-only) {
        width: calc(100% - 16px);
        border-radius: 20px;
        margin-bottom: 8px;
      }
    }

    .rentalModalBody {
      padding: 20px 24px 0px 24px;
      background-color: #F7F7F7;
      width: 907px;

      @include for-size(phone-only) {
        width: 100%;
        padding: 20px 20px 0px 20px;
      }
    }

  }

  @include for-size(tablet-up) {
    .auctionMod {
      overflow-y: initial;
    }

    .auctionTitle {
      position: sticky;
      top: 0;
      background: #fff;
      border-radius: 12px;
      z-index: 9;
    }
  }

  &.beforeLogin {
    .HeaderTitle {
      padding-top: $pad25;
    }

    .modalBody {
      padding: $pad0 $pad32 $pad25;
      
      @include for-size(phone-only) {
        padding: 4px 44px 50px;
      }
    }
  }
}

.rentalOverlay{
  overflow-y: hidden !important;
}

.fullPopup {
  & .modalBody {
    @include for-size(phone-only) {
      max-height: inherit !important;
      height: calc(100% - 52px);
      display: block;
    }
  }
  & .allPropertiesFullPopup{
    @include for-size(phone-only) {
    
      height: calc(100% - 80px) !important;
  
    }
  }
}

.fullWidth{
  width: 96%;
  @include for-size(phone-only) {
    width: 100%;
  }
}
.customWidth{
  max-width: 1072px;
  width: 100%;
  height: calc(100% - 128px);
  &::-webkit-scrollbar{
    width: 8px;
    display: block !important;
  }
  &::-webkit-scrollbar-track {
    margin: 15px 0;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }
  @include for-size(phone-only) {
    width: 100%;
  }
}

.scrollerBarBox{
  height:644px;
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  .HeaderTitle{
    h2{
      color:#000000 !important;
      margin-bottom: 8px;
    }
  }
  &::-webkit-scrollbar{
    display:block !important;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #Fff;
    margin: 15px 0;
    
  }
  
  &::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.sadadScrollerBarBox{
  max-height: 960px;
  height: calc(100vh - 128px);
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  .HeaderTitle{
    h2{
      color:hwb(0 0% 100%) !important;
      margin-bottom: 8px;
      @include for-size(phone-only) {
      margin-bottom: -4px;
      }
    }
  }
  &::-webkit-scrollbar{
    display:block !important;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #Fff;
    margin: 15px 0;
    
  }
  
  &::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.tncCustomClass{
  overflow-y: hidden !important;
  .modalBody{
    height: calc(100% - 32px);
  }
}

.tncIntroClass{
  @include for-size (phone-only){
    padding-bottom: 192px !important;
  }

}

.crossIcon {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(255, 255, 255, 0.70);
  cursor: pointer;
  i {
    align-items: center;
    display: flex;
  }
  @include for-size(tablet-up) {
    position: absolute;
    top: 8px;
    right: 8px;
    html[dir='rtl'] & {
      left: 8px;
      right: auto;
    }
  }
}

.cancelPop {
  @include for-size (phone-only){
    margin-top: 120px;
    align-items: baseline !important;
  }
}

.ContactHeader h2 {
  color: #222 !important;
  font-size: 25px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
}

.HowWorkHeaderTitle h2{
  font-size: 20px !important;
  line-height: 28px !important;
}

.historyModal {
  .modalBox {
    height: calc(100vh - 128px);
    overflow: hidden;

    .modalBody {
      padding: 0px;
      height: 100%;
    }

    @include for-size(phone-only) {
      &.fullPopup {
        height: calc(100vh - 64px);
        border-radius: 20px 20px 0px 0px;
      }
    }
  }
}
.popupDocHeader{
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-bottom: 1px solid #E1E4E8;
  background: #FFF;
  box-shadow: 0px 8px 12px 0px rgba(36, 104, 147, 0.04);
  h2{
    top: -13px;
    position: relative;
    @include for-size(phone-only){
      position:initial;
    }
  }
  @include for-size(phone-only){
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
}

  .backgroundChange {
    background: #F3F6FA;
    padding: 0px !important;
    @include for-size(phone-only){
      padding: 16px 16px 94px !important;
    }
  }

  .widthrawalPopupModal{
    .modalBody{
      padding-bottom: 24px !important;
    }
    @include for-size(phone-only){
              width: unset !important;
              border-radius: 20px 20px 0 0 !important;
              margin-bottom: unset !important;
            .HeaderTitle{
              >h2{
                text-align: center;
                width: 100% !important;
              }
              .crossIcon{
                position: absolute;
                left: 16px;
                html[dir='rtl'] & {
                  right: 16px;
                  left: unset;
                }
              }
            }
            html[dir='rtl'] & {
              width: 100% !important;
            }
            .modalBody{
              padding-top: 4px !important;
              padding-bottom: 8px !important;
            }
    }
  }

  .CapacityDocTitle{
    padding: 12px 20px !important;
    h2{
      position: unset;
    }
  }
  
  .withdrawRequestDeletePop {
    background: #E1E4E8;
    padding: 0px 16px !important;
    @include for-size(phone-only){
      padding: 0px !important;
    }
  }

  .withdrawRequest {
    background: #F3F6FA;
    padding: 0px !important;
  }
  .withDrawHeader {
    justify-content: center !important;
    text-align: center;
    height: 52px;
    .crossIcon{
      right: 12px;
      position: fixed;
      html[dir='rtl'] & {
        left: 12px;
        right: auto;
      }
    }
  }

  .withDrawDeleteHeader {
    justify-content: center !important;
    text-align: center;
    height: 52px;
    border-bottom: 1px solid #F0F0F0;
    .crossIcon{
      right: 12px;
      position: fixed;
      html[dir='rtl'] & {
        left: 12px;
        right: auto;
      }
    }
  }


  .InfathTCHeader {
    padding: 20px !important;
    border-bottom: 1px solid #E1E4E8;
    background: #FFF;
    box-shadow: 4px 10px 20px 0px rgba(36, 104, 147, 0.04);
    @include for-size(phone-only) {
      height: 52px;
      padding-left: 16px !important;
      padding-right: 12px !important;
      html[dir='rtl'] & {
        padding-left: 12px !important;
        padding-right: 16px !important;
      }
  }
  }

  .InfathTCbody{
    overflow-y: hidden !important;
    max-width: 800px;
    .modalBody{
      height: 100% !important;
      padding: 0px !important;
      background: #F3F6FA;
    }
  }